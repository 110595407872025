// Dependencies
import { AxiosResponse } from 'axios'

// Services
import { HttpService } from './http'

// Types
import { GetOrdersQueryParams, Order, OrderModel, UpdateMultipleOrdersInput, UpdateOrderInput } from '../types/orders'

export class OrdersService extends HttpService {
    private path: string

    constructor() {
        super()
        this.path = '/orders'
    }

    getAll = (params?: GetOrdersQueryParams): Promise<AxiosResponse<Order[]>> =>
        this.request({ method: 'get', url: this.path, params })
    
    update = (data: UpdateOrderInput): Promise<AxiosResponse<Order>> =>
        this.request({ method: 'put', url: `${this.path}/${data.order.orderId}`, data })

    delete = (order: OrderModel): Promise<AxiosResponse<void>> =>
        this.request({ method: 'delete', url: `${this.path}/${order.orderId}?ownerId=${order.ownerId}` })
    
    updateMultiple = (data: UpdateMultipleOrdersInput): Promise<AxiosResponse<Order[]>> =>
        this.request({ url: `${this.path}/batch`, data, method: 'put' })

    getLabel = (id: number, params?: { ownerId?: string, parcelsCount?: number } | null): Promise<AxiosResponse<any>> =>
        this.request({
            method: 'get',
            url: `${this.path}/${id}/label`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'arraybuffer',
            params,
        })
}