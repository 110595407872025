// Dependencies
import { CSSProperties, ReactNode } from 'react'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import moment from 'moment'
import { colors } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print'

// Utils
import { RadioButtonOptions } from '../components/common/RadioButtonsList'
import {
  MultipleOrdersSelect,
  Order,
  OrderItemModel,
  OrderItems,
  OrderModel,
  OrderModelFilterableParameters,
  OrderStatusTypes,
  switchesStates
} from '../types/orders'
import { deepCloneObject } from './object-utils'

export const ordersStatus: Array<OrderStatusTypes> = ['new', 'pending', 'packaged', 'completed', 'declined']

export type CardColors = {
  [status in OrderStatusTypes]: string
}

export const mapOrderStatusToColor: CardColors = {
  new: colors.orange['300'],
  pending: colors.red['300'],
  packaged: colors.green['300'],
  completed: colors.blue['300'],
  declined: colors.common.white
}
interface ButtonActions {
  text?: string
  action?: OrderStatusTypes | 'delete'
  style?: CSSProperties
  icon?: ReactNode
  adminOnly?: boolean
}

export type CardButton = {
  [status in OrderStatusTypes]: Array<ButtonActions>
}

export const checkOrderStatus = (order: OrderModel, column: OrderStatusTypes) => order.status === column

export const mockedOrders: Array<OrderModel> = [
  {
      orderId: 1,
      status: 'new',
      date: moment().subtract(1, 'day').toDate(),
      totalPrice: '11,52',
      totalAmount: 6,
      clientId: 1,
      items: [
        {
          productId: 1,
          title: '200',
          product: 'Coffe A', 
          price: 2.25,
          quantity: 2,
          amount: 2,
          vendor: 'roaster A',
        },
        {
          productId: 1,
          title: '400',
          product: 'Coffe B', 
          price: 1.5,
          quantity: 4,
          amount: 4,
          vendor: 'roaster A',
        }
      ]
  },
  {
      orderId: 2,
      status: 'new',
      date: moment().toDate(),
      totalPrice: '73,77',
      totalAmount: 6,
      clientId: 2,
      items: [
        {
          productId: 2,
          title: '300',
          product: 'Coffe B', 
          price: 6.00,
          quantity: 6,
          amount: 6,
          vendor: 'roaster B',
        },
      ]
  },
  {
      orderId: 3,
      status: 'pending',
      date: moment().subtract(3, 'day').toDate(),
      totalPrice: '16,64',
      totalAmount: 5,
      clientId: 3,
      items: [
        {
          productId: 2,
          title: '800',
          product: 'Coffe B', 
          price: 6.00,
          quantity: 4,
          amount: 4,
          vendor: 'roaster B',
        },
        {
          productId: 3,
          title: '300',
          product: 'Coffe C', 
          price: 11.20,
          quantity: 1,
          amount: 1,
          vendor: 'roaster B',
        },
      ]
  },
  {
      orderId: 4,
      status: 'packaged',
      date: moment().subtract(7, 'day').toDate(),
      totalPrice: '45,13',
      totalAmount: 11,
      clientId: 4,
      items: [
        {
          productId: 4,
          title: '100',
          product: 'Coffe D', 
          price: 18.20,
          quantity: 2,
          amount: 2,
          vendor: 'roaster B',
        },
        {
          productId: 1,
          title: '200',
          product: 'Coffe A', 
          price: 2.25,
          quantity: 4,
          amount: 4,
          vendor: 'roaster A',
        },
        {
          productId: 3,
          title: '300',
          product: 'Coffe C', 
          price: 11.20,
          quantity: 5,
          amount: 5,
          vendor: 'roaster B',
        },
      ],
  },
  {
      orderId: 5,
      status: 'packaged',
      date: moment().subtract(8, 'day').toDate(),
      totalPrice: '8,90',
      totalAmount: 5,
      clientId: 5,
      items: [
        {
          productId: 3,
          title: '300',
          product: 'Coffe C', 
          price: 11.20,
          quantity: 5,
          amount: 5,
          vendor: 'roaster B',
        },
      ]
  },
  {
      orderId: 6,
      status: 'completed',
      date: moment().subtract(10, 'day').toDate(),
      totalPrice: '8,90',
      totalAmount: 8,
      clientId: 6,
      items: [
        {
          productId: 3,
          title: '300',
          product: 'Coffe C', 
          price: 11.20,
          quantity: 8,
          amount: 8,
          vendor: 'roaster B',
        },
      ]
  },
]

export const mapButtonByOrderStatus: CardButton = {
  new: [
    {
      text: 'accept',
      action: 'pending',
    },
    {
      text: 'reject',
      action: 'declined',
      style: {
        color: 'white',
        backgroundColor: colors.red['700'],
        borderColor: colors.red['700'],
      },
    },
    {
      text: 'delete',
      action: 'delete',
      adminOnly: true,
      style: {
        color: 'white',
        backgroundColor: colors.red['700'],
        borderColor: colors.red['700'],
      },
    },
  ],
  pending: [
    {
      text: 'ready',
      action: 'packaged',
    },
  ],
  packaged: [
    {
      text: 'print',
      icon: <PrintIcon sx={{ fontSize: '1.2vw' }} />,
    },
    {
      text: 'complete',
      action: 'completed',
    },
    {
      text: 'pending',
      action: 'pending',
      icon: <KeyboardReturnIcon sx={{ fontSize: '1.2vw' }} />,
      style: {
        marginTop: '0.5vw',
      },
    },
  ],
  completed: [
    {
      text: 'packaged',
      action: 'packaged',
      icon: <KeyboardReturnIcon style={{ fontSize: '1.2vw' }} />,
    },
  ],
  declined: [],
};

export const optionsList: Array<RadioButtonOptions> = [
  { value: 0, label: `orders.modal.options.0`, type: 'radio' },
  { value: 1, label: `orders.modal.options.1`, type: 'radio' },
  { value: 2, label: `orders.modal.options.2`, type: 'radio' },
]

type SwitchLabels = 'status' | 'amount' | 'priority'

type SwitchEvents = 'filterPriorityOrders'

type SwitchArray = {
  label: SwitchLabels
  dispatchEvent?: SwitchEvents
}

export const switchesLabels: Array<SwitchArray> = [
  { label: 'status' },
  { label: 'amount' },
  { label: 'priority', dispatchEvent: 'filterPriorityOrders' }
]

export const switchesStatesObj: {[k in SwitchLabels]: switchesStates} = {
  status: 'showDeclinedOrders',
  amount: 'showTotalQuantity',
  priority: 'showPriorityOrders',
}

export const mapSwitchToState = (label: SwitchLabels, value: boolean): {
  showDeclinedOrders?: boolean
  showTotalQuantity?: boolean
  showPriorityOrders?: boolean
} => ({
  [switchesStatesObj[label]]: value
})

export const mapOrderModelItems = (items: OrderItems[], order: Order): OrderItemModel[] => items.map(item => ({
  productId: item.variant.product_id,
  title: item.variant.title,
  product: item.lineItem.title,
  price: item.lineItem.price,
  quantity: item.lineItem.quantity,
  amount: item.lineItem.quantity,
  vendor: item.lineItem.vendor,
  orderNumber: order.orderNumber,
}))

export const mapOrderModel = (order: Order): OrderModel => ({
  orderId: order.id,
  status: order.status || 'new', // adjust
  date: order.createdAt,
  items: mapOrderModelItems(order.items, order),
  totalAmount: order.totalAmount,
  reason: order.reason,
  ownerId: order.ownerId,
  totalWeight: order.totalWeight,
})

export const findOrderByStatus = (orders: OrderModel[], status: OrderStatusTypes): boolean =>
  !!orders.find(order => order.status === status)

export const getOrdersByStatus = (orders: OrderModel[], status: OrderStatusTypes): { data: OrderModel[], maxCount: number } => {
  let maxCount: number = 0
  const data = orders.filter(order => {
    if (order.items.length > maxCount) {
      maxCount = order.items.length
    }
    return order.status === status
  })

  return {
    data,
    maxCount,
  }
}

export const getOrdersByParamAndAction = (orders: OrderModel[], param: OrderModelFilterableParameters, value: number | string,  action: 'equal' | 'diff') => {
  if (action === 'equal') {
    return orders.filter(order => order[param] === value)
  }
  return orders.filter(order => order[param] !== value)
}

export const getOrdersByStatusAndAction = (orders: OrderModel[], status: OrderStatusTypes, action: 'equal' | 'diff'): OrderModel[] => {
  if (action === 'equal') {
    return getOrdersByStatus(orders, status).data
  }
  return orders.filter(order => order.status !== status)
}

export const getUpdatedSelecterOrders = (oders: OrderModel[], selectedOrders: MultipleOrdersSelect, updatedOrders: OrderModel[]): OrderModel[] => {
  const data = deepCloneObject(oders).filter((order: OrderModel) => !selectedOrders[order.orderId])
  data.push(...updatedOrders)
  return data
}

export const PRIORITY_ORDERS_TIME: moment.DurationInputArg1 = '13:30'

export const PRIORITY_ORDERS_TIME_UNIT: moment.unitOfTime.DurationConstructor = 'h'