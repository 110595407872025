import { RootState } from '../../../redux/store';
import { ManageOrdersActions, ManageOrdersActionsTypes } from "./types"
import { FilterOrdersSwitchesState, MultipleOrdersSelect, OrderModel, OrdersDeclineTranslatedOptions, switchesStates } from '../../../types/orders'
import moment from 'moment';
import { deepCloneObject } from '../../../utils/object-utils';
import { getUpdatedSelecterOrders } from '../../../utils/orders-utils';

export interface ManageOrdersState {
    orders: OrderModel[]
    filteredOrders: OrderModel[]
    selectedOrder: OrderModel | any
    isLoading: boolean
    snackbar: any
    isOrderUpdating: boolean
    reason: OrdersDeclineTranslatedOptions | ''
    selectedCardOrders: MultipleOrdersSelect
    switches: FilterOrdersSwitchesState
}

export const manageOrdersInitialState: ManageOrdersState = {
    orders: [],
    filteredOrders: [],
    selectedOrder: {},
    isLoading: false,
    snackbar: null,
    isOrderUpdating: false,
    reason: '',
    selectedCardOrders: {},
    switches: {
        showDeclinedOrders: false,
        showTotalQuantity: true,
        showPriorityOrders: false,
    },
}

export const ordersSelector = (state: RootState): OrderModel[] => state.manageOrders.orders

export const filteredOrdersSelector = (state: RootState): OrderModel[] => state.manageOrders.filteredOrders

export const selectedOrderSelector = (state: RootState): OrderModel => state.manageOrders.selectedOrder

export const isLoadingSelector = (state: RootState): boolean => state.manageOrders.isLoading

export const snackbarSelector = (state: RootState): any => state.manageOrders.snackbar

export const isOrderUpdatingSelector = (state: RootState): boolean => state.manageOrders.isOrderUpdating

export const orderDeclinementReasonSelector = (state: RootState): OrdersDeclineTranslatedOptions | '' => state.manageOrders.reason

export const selectedCardOrdersSelector = (state: RootState): MultipleOrdersSelect => state.manageOrders.selectedCardOrders

export const switchesSelector = (state: RootState): FilterOrdersSwitchesState => state.manageOrders.switches

export const manageOrdersReducer = (
    state: ManageOrdersState = manageOrdersInitialState,
    action: ManageOrdersActions,
): ManageOrdersState => {
    switch (action.type) {
      case ManageOrdersActionsTypes.GET_ALL:
        return {
          ...state,
          isLoading: action.payload.useLoading ? !manageOrdersInitialState.isLoading : false,
        };
      case ManageOrdersActionsTypes.GET_ALL_SUCCESS: {
        let data = state.orders;
        if (data.length !== action.payload.length) {
          data = action.payload;
        }
        return {
          ...state,
          isLoading: manageOrdersInitialState.isLoading,
          orders: data,
        };
      }
      case ManageOrdersActionsTypes.GET_ALL_ERROR:
        return {
          ...state,
          isLoading: manageOrdersInitialState.isLoading,
          snackbar: action.payload,
        };
      case ManageOrdersActionsTypes.FILTER_ORDERS: {
        let data: OrderModel[] = [];
        const { dateFrom, dateTo, dateError, orders } = action.payload;

        if (!dateFrom || !dateTo || !!dateError) {
          data = JSON.parse(JSON.stringify(orders));
        }

        data = orders
          .filter(
            (order) =>
              moment(order.date).isSameOrAfter(dateFrom) &&
              moment(order.date).isSameOrBefore(moment(dateTo).add(1, 'd'))
          )
          .sort((a, b) => moment(a.date).unix() - moment(b.date).unix());

        return {
          ...state,
          filteredOrders: data,
        };
      }
      case ManageOrdersActionsTypes.SELECT_ORDER:
        return {
          ...state,
          selectedOrder: action.payload,
        };
      case ManageOrdersActionsTypes.UPDATE_STATUS:
        return {
          ...state,
          isOrderUpdating: !manageOrdersInitialState.isOrderUpdating,
        };
      case ManageOrdersActionsTypes.UPDATE_STATUS_SUCCESS:
        return {
          ...state,
          reason: manageOrdersInitialState.reason,
          isOrderUpdating: manageOrdersInitialState.isOrderUpdating,
          orders: action.payload.orders,
          snackbar: action.payload.snackbar,
        };
      case ManageOrdersActionsTypes.UPDATE_STATUS_ERROR:
        return {
          ...state,
          isOrderUpdating: manageOrdersInitialState.isOrderUpdating,
          snackbar: action.payload,
        };

      case ManageOrdersActionsTypes.ADMIN_DELETE_ORDER:
        return {
          ...state,
          isOrderUpdating: manageOrdersInitialState.isOrderUpdating,
        };

      case ManageOrdersActionsTypes.ADMIN_DELETE_ORDER_SUCCESS:
        return {
          ...state,
          isOrderUpdating: manageOrdersInitialState.isOrderUpdating,
          orders: action.payload.orders,
          snackbar: action.payload.snackbar,
        };

      case ManageOrdersActionsTypes.ADMIN_DELETE_ORDER_ERROR:
        return {
          ...state,
          isOrderUpdating: manageOrdersInitialState.isOrderUpdating,
          snackbar: action.payload,
        };

      case ManageOrdersActionsTypes.CLEAR_FEEDBACK_MESSAGE:
        return {
          ...state,
          snackbar: manageOrdersInitialState.snackbar,
        };
      case ManageOrdersActionsTypes.ORDER_DECLINEMENT_REASON:
        return {
          ...state,
          reason: action.payload,
        };
      case ManageOrdersActionsTypes.SELECT_MULTIPLE_ORDERS: {
        const data = deepCloneObject(state.selectedCardOrders);
        const order = action.payload;

        if (!order) {
          return {
            ...state,
            selectedCardOrders: manageOrdersInitialState.selectedCardOrders,
          };
        } else if (order.status !== 'packaged') {
          return {
            ...state,
          };
        }

        if (!data[order.orderId]) {
          data[order.orderId] = true;
        } else {
          delete data[order.orderId];
        }

        return {
          ...state,
          selectedCardOrders: data,
        };
      }
      case ManageOrdersActionsTypes.HANDLE_SWITCHES: {
        let obj: FilterOrdersSwitchesState = {
          ...state.switches,
          ...action.payload,
        };

        if (!action.payload) {
          obj = manageOrdersInitialState.switches;
        }
        return {
          ...state,
          switches: obj,
        };
      }
      case ManageOrdersActionsTypes.UPDATE_MULTIPLE_STATUS:
        return {
          ...state,
          isOrderUpdating: !manageOrdersInitialState.isOrderUpdating,
        };
      case ManageOrdersActionsTypes.UPDATE_MULTIPLE_STATUS_SUCCESS: {
        const orders: OrderModel[] = getUpdatedSelecterOrders(
          state.orders,
          state.selectedCardOrders,
          action.payload.orders
        );
        const filteredOrders: OrderModel[] = getUpdatedSelecterOrders(
          state.filteredOrders,
          state.selectedCardOrders,
          action.payload.orders
        );
        return {
          ...state,
          isOrderUpdating: manageOrdersInitialState.isOrderUpdating,
          selectedCardOrders: manageOrdersInitialState.selectedCardOrders,
          snackbar: action.payload.snackbar,
          orders,
          filteredOrders,
        };
      }
      case ManageOrdersActionsTypes.UPDATE_MULTIPLE_STATUS_ERROR:
        return {
          ...state,
          snackbar: action.payload,
          isOrderUpdating: manageOrdersInitialState.isOrderUpdating,
          selectedCardOrders: manageOrdersInitialState.selectedCardOrders,
        };
      case ManageOrdersActionsTypes.PRINT:
        return {
          ...state,
          isOrderUpdating: !manageOrdersInitialState.isOrderUpdating,
        };
      case ManageOrdersActionsTypes.PRINT_SUCCESS:
        return {
          ...state,
          isOrderUpdating: manageOrdersInitialState.isOrderUpdating,
        };
      case ManageOrdersActionsTypes.PRINT_ERROR:
        return {
          ...state,
          isOrderUpdating: manageOrdersInitialState.isOrderUpdating,
          snackbar: action.payload,
        };
      default:
        return state;
    }
}