// Dependencies
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { catchError, map, mergeMap, finalize } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';

// States
import {
  AdminDeleteOrderAction,
  GetAllAction,
  ManageOrdersActions,
  ManageOrdersActionsTypes,
  UpdateStatusAction,
} from './types';
import {
  getAllOrdersError,
  getAllOrdersSuccess,
  clearFeedbackMessage,
  updateOrderStatusSuccess,
  updateOrderStatusError,
  updateMultipleStatusSuccess,
  updateMultipleStatusError,
  printSuccess,
  printError,
  adminDeleteOrderSuccess,
} from './actions';
import { ordersSelector, selectedCardOrdersSelector } from './reducer';

// Utils
import { OrdersService } from '../../../services/orders';
import { mapOrderModel } from '../../../utils/orders-utils';
import { translate } from '../../../i18n';
import { AdminDeleteOrderInput, OrderModel, UpdateOrderInput } from '../../../types/orders';
import { RootState } from '../../../redux/store';
import { deepCloneObject } from '../../../utils/object-utils';

const ordersService = new OrdersService();

export const getAllOrdersEpic = (actions$: Observable<ManageOrdersActions>): any =>
  actions$.pipe(
    ofType<ManageOrdersActions, ManageOrdersActionsTypes.GET_ALL, GetAllAction>(ManageOrdersActionsTypes.GET_ALL),
    mergeMap(({ payload: { dateFrom, dateTo } }) =>
      from(ordersService.getAll({ dateFrom: dateFrom?.toISOString(), dateTo: dateTo?.toISOString() })).pipe(
        map(({ data: orders }) => {
          const mappedOrders = orders.map(mapOrderModel);
          return getAllOrdersSuccess(mappedOrders);
        }),
        catchError((error) => {
          console.log(error);
          return of(getAllOrdersError({ children: translate('general.apiError'), severity: 'error' }));
        }),
        finalize(() => {
          clearFeedbackMessage();
        })
      )
    )
  );

export const updateOrderStatusEpic = (
  actions$: Observable<ManageOrdersActions>,
  state$: StateObservable<RootState>
): any =>
  actions$.pipe(
    ofType<ManageOrdersActions, ManageOrdersActionsTypes.UPDATE_STATUS, UpdateStatusAction>(
      ManageOrdersActionsTypes.UPDATE_STATUS
    ),
    mergeMap(({ payload: { order, status, reason } }: { payload: UpdateOrderInput }) =>
      from(ordersService.update({ status, reason, order, ownerId: order.ownerId })).pipe(
        map(() => {
          let orders: OrderModel[] = deepCloneObject(ordersSelector(state$.value));
          orders = orders.map((item) => {
            if (item.orderId === order.orderId && item.ownerId === order.ownerId) {
              item.status = status;
            }
            return item;
          });
          return updateOrderStatusSuccess({
            orders,
            snackbar: { children: translate('orders.feedback.statusUpdate.success', { status }), severity: 'success' },
          });
        }),
        catchError(() =>
          of(updateOrderStatusError({ children: translate('orders.feedback.statusUpdate.error'), severity: 'error' }))
        ),
        finalize(() => {
          clearFeedbackMessage();
        })
      )
    )
  );

export const updateMultipleOrdersStatusEpic = (
  actions$: Observable<ManageOrdersActions>,
  state$: StateObservable<RootState>
): any =>
  actions$.pipe(
    ofType<ManageOrdersActions, ManageOrdersActionsTypes.UPDATE_MULTIPLE_STATUS, UpdateStatusAction>(
      ManageOrdersActionsTypes.UPDATE_MULTIPLE_STATUS
    ),
    mergeMap(({ payload: { status } }) => {
      const selectedCardOrders = selectedCardOrdersSelector(state$.value);
      let ids: number[] = [];
      for (let id in selectedCardOrders) {
        ids.push(parseFloat(id));
      }
      return from(ordersService.updateMultiple({ order: { status }, ids })).pipe(
        map(({ data: orders }) => {
          const mappedOrders = orders.map(mapOrderModel);
          return updateMultipleStatusSuccess({
            orders: mappedOrders,
            snackbar: { children: translate('orders.feedback.statusUpdate.success', { status }), severity: 'success' },
          });
        }),
        catchError((error) => {
          console.log(error);
          return of(
            updateMultipleStatusError({ children: translate('orders.feedback.statusUpdate.error'), severity: 'error' })
          );
        }),
        finalize(() => clearFeedbackMessage())
      );
    })
  );

export const printEpic = (actions$: Observable<ManageOrdersActions>): any =>
  actions$.pipe(
    ofType<ManageOrdersActions, ManageOrdersActionsTypes.PRINT, UpdateStatusAction>(ManageOrdersActionsTypes.PRINT),
    mergeMap(({ payload: { orderId, params } }) => {
      return from(ordersService.getLabel(orderId, params)).pipe(
        map(({ data, status }) => {
          if (status !== 204) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `order-${orderId}.pdf`);
            document.body.appendChild(link);
            link.click();
          }
          return printSuccess();
        }),
        catchError(() => of(printError({ children: translate('orders.feedback.print.error'), severity: 'error' })))
      );
    })
  );

export const adminDeleteOrderEpic = (
  actions$: Observable<ManageOrdersActions>,
  state$: StateObservable<RootState>
): any =>
  actions$.pipe(
    ofType<ManageOrdersActions, ManageOrdersActionsTypes.ADMIN_DELETE_ORDER, AdminDeleteOrderAction>(
      ManageOrdersActionsTypes.ADMIN_DELETE_ORDER
    ),
    mergeMap(({ payload: { order } }: { payload: AdminDeleteOrderInput }) => {
      if (!window.confirm(translate('orders.feedback.adminOrderDelete.confirm'))) {
        return of();
      }
      return from(ordersService.delete(order)).pipe(
        map(() => {
          let orders: OrderModel[] = deepCloneObject(ordersSelector(state$.value));
          let deleted = false;
          orders = orders.filter((item) => {
            if (item.orderId === order.orderId && item.ownerId === order.ownerId) {
              deleted = true;
              return false;
            }
            return true;
          });

          return adminDeleteOrderSuccess({
            orders,
            snackbar: {
              children: translate('orders.feedback.adminOrderDelete.success'),
              severity: 'success',
            },
          });
        }),
        catchError(() =>
          of(printError({ children: translate('orders.feedback.adminOrderDelete.error'), severity: 'error' }))
        )
      );
    })
  );

export const maganeOrdersEpic: any = combineEpics(
  getAllOrdersEpic,
  updateOrderStatusEpic,
  updateMultipleOrdersStatusEpic,
  printEpic,
  adminDeleteOrderEpic
);
