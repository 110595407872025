// Dependencies
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Toolbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

// Components
import Sidebar from '../common/Sidebar'
import Topbar from '../common/Topbar'
import { CentralCircularLoader } from '../common/CircularLoader'

// Utils
import colorConfigs from '../../configs/colorConfigs'
import sizeConfigs from '../../configs/sizeConfigs'
import { selectCurrentPath, selectShowSideMenu } from '../../redux/features/appStateSlice'
import { fetchUser, getLoggedUser, isUserLoadingSelector } from '../../redux/features/userStateSlice'
import { WatchNavigationStates } from '../../utils/navigation-utils'

const MainLayout = () => {
    WatchNavigationStates()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const showSideMenu = useSelector(selectShowSideMenu)
    const currentPath = useSelector(selectCurrentPath)
    const isUserLoading = useSelector(isUserLoadingSelector)
    const user = getLoggedUser(useSelector)

    const [width, setWidth] = React.useState<number>(sizeConfigs.mainScreen.widthRaw)
    
    React.useEffect(() => {
        if (window.location.pathname === '/' || !window.location.pathname) {
            navigate('/products/add')
        }
    }, [navigate])

    React.useEffect(() => {
        let newWidth: number = showSideMenu
            ? sizeConfigs.mainScreen.widthRaw - sizeConfigs.sidebar.widthRaw
            : sizeConfigs.mainScreen.widthRaw
        setWidth(newWidth)
    }, [showSideMenu])

    React.useEffect(() => {
        if (currentPath === 'products.add') {
            fetchUser(user?.userId, dispatch)
        }
    }, [currentPath, dispatch])

    if (isUserLoading) {
        return <CentralCircularLoader />
    }

    return (
      <Box sx={{ display: 'flex' }}>
        <Topbar />
        <Box
          component='nav'
          sx={{
            width: showSideMenu ? sizeConfigs.sidebar.width : 0,
            flexShrink: 0,
          }}
        >
          <Sidebar />
        </Box>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            p: sizeConfigs.mainScreen.padding,
            width: `${width}%`,
            minHeight: '100vh',
            backgroundColor: colorConfigs.mainBg,
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '80px'
          }}
        >
          <Outlet />
        </Box>
      </Box>
    );
};

export default MainLayout