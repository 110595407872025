// Dependencies
import React from 'react'
import Alert from '@mui/material/Alert'
import { Box, Button, colors, Snackbar, Typography } from '@mui/material'
import moment, { Moment } from 'moment'
import { DateValidationError } from '@mui/x-date-pickers'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Components
import OrdersContainer from '../../components/common/OrdersContainer'
import Modal from '../../components/common/Modal'
import OrdersFilters from '../../components/common/OrdersFilters'
import CircularLoader, { CentralCircularLoader } from '../../components/common/CircularLoader'
import FixedButton from '../../components/common/FixedButton'
import RoundButton from '../../components/common/RoundButton'

// Utils
import { mapSwitchToState, optionsList } from '../../utils/orders-utils'
import {
    filteredOrdersSelector,
    isLoadingSelector,
    orderDeclinementReasonSelector,
    ordersSelector,
    selectedCardOrdersSelector,
    selectedOrderSelector,
    snackbarSelector
} from './states/reducer'
import {
    clearFeedbackMessage,
    filterOrders,
    getAllOrders,
    setOrderDeclinementReason,
    selectOrder,
    updateOrderStatus,
    updateMultipleStatus,
    handleSwitches,
    print,
} from './states/actions'
import { getOrdersCsvData } from '../../utils/csv-utils'
import { TOPBAR_HEIGHT } from '../../components/common/Topbar'
import { isObjectEmpty } from '../../utils/object-utils'
import RadioButtonsList from '../../components/common/RadioButtonsList'
import { getLoggedUser, ownerUserSelector } from '../../redux/features/userStateSlice'
import { OrderCsvData } from '../../types/orders'
import { TextFieldInput } from '../../components/common/Inputs'
import colorConfigs from '../../configs/colorConfigs'


const MAX_BOXES = 3

const OrdersPage = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const ownerUser = useSelector(ownerUserSelector)
    const orders = useSelector(ordersSelector)
    const filteredOrders = useSelector(filteredOrdersSelector)
    const isLoading = useSelector(isLoadingSelector)
    const snackbar = useSelector(snackbarSelector)
    const selectedOrder = useSelector(selectedOrderSelector)
    const reason = useSelector(orderDeclinementReasonSelector)
    const selectedCardOrders = useSelector(selectedCardOrdersSelector)
    const user = getLoggedUser(useSelector)
    const isAdmin = user.isAdmin

    const [dateFrom, setDateFrom] = React.useState<Moment | null>(moment().subtract(7, 'd'))
    const [dateTo, setDateTo] = React.useState<Moment | null>(moment())
    const [dateError, setDateError] = React.useState<DateValidationError | undefined>()
    // const [csv, setCsv] = React.useState<{ datas: Datas, columns: Columns }>({ datas: [], columns: [] })
    const [csv, setCsv] = React.useState<Partial<OrderCsvData>>({})

    const handleCloseSnackbar = React.useCallback(
        () => dispatch(clearFeedbackMessage()), [dispatch]
    )

    React.useEffect(
        () =>
            window.matchMedia("(orientation: portrait)").addEventListener('change', () => {
                dispatch(filterOrders({ dateError, dateFrom, dateTo, orders }))
            }),
        [dateFrom, dateTo, orders, dateError, dispatch]
    )

    React.useEffect(() => {
        dispatch(getAllOrders({ useLoading: true, dateFrom, dateTo }))
    }, [dispatch, ownerUser?.ownerId, dateFrom, dateTo])

    React.useEffect(() => {
        const get = () => dispatch(getAllOrders({ useLoading: false, dateFrom, dateTo }))
        const interval = setInterval(() => {
            get()
            const date = moment()
            const hour = date.hour()
            const minute = date.minute()
            if (hour === 6 && minute <= 1) {
                dispatch(handleSwitches(mapSwitchToState('priority', false)))
            }
        }, 20000)
        return () => {
            clearInterval(interval);
        }
    }, [dispatch, dateFrom, dateTo])

    // React.useEffect(() => {
    //     dispatch(filterOrders({ dateError, dateFrom, dateTo, orders }))
    // }, [orders, dateFrom, dateTo, dateError, dispatch])

    React.useEffect(() => {
        dispatch(filterOrders({ dateError, dateFrom, dateTo, orders }))
    }, [orders, dateError, dispatch])

    React.useEffect(() => {
        const pending = getOrdersCsvData(filteredOrders, t, 'pending')
        const completed = getOrdersCsvData(filteredOrders, t, 'completed')
        setCsv({ pending, completed })
    }, [t, filteredOrders])

    const handleClose = () => {
        dispatch(selectOrder(null))
        dispatch(setOrderDeclinementReason(''))
    }

    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          // maxHeight: (window.innerHeight - TOPBAR_HEIGHT) * 0.85,
          // overflowY: 'scroll',
          // flexWrap: 'nowrap',
          // position: 'relative',
          marginBottom: 5,
        }}
      >
        <OrdersFilters
          dateError={dateError}
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setDateError={setDateError}
        />
        <OrdersContainer csv={csv} orders={filteredOrders} />
        {selectedOrder?.orderId && (
          <Modal
            show={!!selectedOrder?.orderId}
            handleClose={handleClose}
            children={
              <React.Fragment>
                {selectedOrder.status === 'new' && (
                  <RadioButtonsList
                    onSend={() => dispatch(updateOrderStatus({ order: selectedOrder, reason, status: 'declined' }))}
                    handleClose={handleClose}
                    buttonLabel={t('products.manage.modal.button.label')}
                    title={t('products.manage.modal.title')}
                    options={optionsList}
                  />
                )}
                {selectedOrder.status === 'declined' && (
                  <Box>
                    <Typography>
                      <strong style={{ color: colors.grey[700] }}>{t('orders.modal.title2')}: </strong>
                      {selectedOrder.reason}
                    </Typography>
                  </Box>
                )}
                {selectedOrder.status === 'packaged' && (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '2vw',
                        fontWeight: 'medium',
                        color: colors.grey['800'],
                      }}
                    >
                      {t('orders.modal.title3')}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '4vh' }}>
                      {Array.from({ length: MAX_BOXES }, (_, i) => i + 1).map((parcelsCount) => (
                        <Button
                          key={parcelsCount}
                          variant='contained'
                          sx={{
                            backgroundColor: colorConfigs.sidebar.bg,
                            width: '8vh',
                            height: '8vh',
                            fontSize: '4vh',
                          }}
                          onClick={() => {
                            const params = { 
                              ownerId: isAdmin ? selectedOrder.ownerId : undefined,
                              parcelsCount 
                            };
                            dispatch(print({ orderId: selectedOrder.orderId, params }));
                            handleClose();
                          }}
                        >
                          {parcelsCount}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                )}
              </React.Fragment>
            }
          />
        )}
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={4000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
        {/* {isLoading && <CircularLoader />} */}
        {isLoading && <CentralCircularLoader />}
        {!isObjectEmpty(selectedCardOrders) && (
          <FixedButton
            label={t('orders.buttons.moveAllToCompleted')}
            onClick={() => dispatch(updateMultipleStatus({ status: 'completed' }))}
          />
        )}
      </Box>
    );
}

export default OrdersPage